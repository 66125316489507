import React, { Component } from 'react';

class Cotizador extends Component{
    constructor(props){
        super(props);
        this.handleClickLogos = this.handleClickLogos.bind(this);
        this.handleClickLogosLogotipo = this.handleClickLogosLogotipo.bind(this);
        this.handleClickLogosIsotipo = this.handleClickLogosIsotipo.bind(this);
        this.handleClickLogosIsologo = this.handleClickLogosIsologo.bind(this);
        this.handleClickLogosImagotipo = this.handleClickLogosImagotipo.bind(this);
        //VIDEO PROMOCIONAL
        this.handleClickVideoPromocionalLocacion = this.handleClickVideoPromocionalLocacion.bind(this);
        this.handleClickVideoPromocionalLocacionSi = this.handleClickVideoPromocionalLocacionSi.bind(this);
        this.handleClickVideoPromocionalLocacionNo = this.handleClickVideoPromocionalLocacionNo.bind(this);
        this.handleClickVideoPromocionalLocacionNoSe = this.handleClickVideoPromocionalLocacionNoSe.bind(this);
        this.handleClickVideoPromocionalLocacion1Minuto = this.handleClickVideoPromocionalLocacion1Minuto.bind(this);
        this.handleClickVideoPromocionalLocacion2Minuto = this.handleClickVideoPromocionalLocacion2Minuto.bind(this);
        this.handleClickVideoPromocionalLocacion3Minuto = this.handleClickVideoPromocionalLocacion3Minuto.bind(this);
        //VIDEO EMPRESARIAL
        this.handleClickVideoEmpresarialLocacion = this.handleClickVideoEmpresarialLocacion.bind(this);
        this.handleClickVideoEmpresarialLocacionSi = this.handleClickVideoEmpresarialLocacionSi.bind(this);
        this.handleClickVideoEmpresarialLocacionNo = this.handleClickVideoEmpresarialLocacionNo.bind(this);
        this.handleClickVideoEmpresarialLocacionNoSe = this.handleClickVideoEmpresarialLocacionNoSe.bind(this);
        this.handleClickVideoEmpresarialLocacion1Minuto = this.handleClickVideoEmpresarialLocacion1Minuto.bind(this);
        this.handleClickVideoEmpresarialLocacion2Minuto = this.handleClickVideoEmpresarialLocacion2Minuto.bind(this);
        this.handleClickVideoEmpresarialLocacion3Minuto = this.handleClickVideoEmpresarialLocacion3Minuto.bind(this);
        //FOTO PRODUCTOS
        this.handleClickFotoProductos = this.handleClickFotoProductos.bind(this);
        this.handleClickFotoProductosPequeños = this.handleClickFotoProductosPequeños.bind(this);
        this.handleClickFotoProductosMedianos = this.handleClickFotoProductosMedianos.bind(this);
        this.handleClickFotoProductosGrandes = this.handleClickFotoProductosGrandes.bind(this);
        this.handleClickFotoProductoFondoSi = this.handleClickFotoProductoFondoSi.bind(this);
        this.handleClickFotoProductoFondoNo = this.handleClickFotoProductoFondoNo.bind(this);
        this.handleClickFotoProductoFondoNoSe = this.handleClickFotoProductoFondoNoSe.bind(this);
        this.handleClickFotoProductoModeloSi = this.handleClickFotoProductoModeloSi.bind(this);
        this.handleClickFotoProductoModeloNo = this.handleClickFotoProductoModeloNo.bind(this);
        this.handleClickFotoProductoModeloNoSe = this.handleClickFotoProductoModeloNoSe.bind(this);
        //CONTENIDO DE BLOGS
        this.handleClickContenidoBlog = this.handleClickContenidoBlog.bind(this);
        this.handleClickContenidoBlog300 = this.handleClickContenidoBlog300.bind(this);
        this.handleClickContenidoBlog750 = this.handleClickContenidoBlog750.bind(this);
        this.handleClickContenidoBlog1500 = this.handleClickContenidoBlog1500.bind(this);
        this.handleClickContenidoBlogImagenSi = this.handleClickContenidoBlogImagenSi.bind(this);
        this.handleClickContenidoBlogImagenNo = this.handleClickContenidoBlogImagenNo.bind(this);
        this.handleClickContenidoBlogImagenNoSe = this.handleClickContenidoBlogImagenNoSe.bind(this);
        this.handleClickContenidoBlogVideoSi = this.handleClickContenidoBlogVideoSi.bind(this);
        this.handleClickContenidoBlogVideoNo = this.handleClickContenidoBlogVideoNo.bind(this);
        this.handleClickContenidoBlogVideoNoSe = this.handleClickContenidoBlogVideoNoSe.bind(this);

        this.state = {
            pageLogo1: true,
            pageLogo2: false,
            pageLogoEnd: false,
            pageVideoPromocional1: false,
            pageVideoPromocional2: false,
            pageVideoPromocionalFinal: false,
            pageVideoEmpresarial1: false,
            pageVideoEmpresarial2: false,
            pageVideoEmpresarialFinal: false,
            pageFotoProductos: false,
            pageFotoProductos2: false,
            pageFotoProductos3: false,
            pageFotoProductosFinal: false,
            pageContenidoBlog: false,
            pageContenidoBlog2: false,
            pageContenidoBlog3: false,
            pageContenidoBlogFinal: false,
            total: 0
        }
    }

    //***************************************************************************************************************************************** */
    //***************************************************************************************************************************************** */
    //lOGOTIPOS ******************************************************************************************************************************* */
    //***************************************************************************************************************************************** */
    //***************************************************************************************************************************************** */
    handleClickLogos(){
        if(this.state.pageLogo2){
            this.setState({pageLogo2: false});
        }else{
            this.setState({pageLogo1: false});
            this.setState({pageLogo2: true});
        }
    }

    handleClickLogosLogotipo(){
        var total = this.state.total;
        if(this.state.pageLogoEnd){
            this.setState({pageLogoEnd: false});
        }else{
            total = total + 2500;
            this.setState({total : total});
            this.setState({pageLogo2: false});
            this.setState({pageLogoEnd: true});
        }
    }

    handleClickLogosIsotipo(){
        if(this.state.pageLogoEnd){
            this.setState({pageLogoEnd: false});
        }else{
            var total = this.state.total;
            total = total + 3500;
            this.setState({total : total});
            this.setState({pageLogo2: false});
            this.setState({pageLogoEnd: true});
        }
    }

    handleClickLogosImagotipo(){
        if(this.state.pageLogoEnd){
            this.setState({pageLogoEnd: false});
        }else{
            var total = this.state.total;
            total = total + 4500;
            this.setState({total : total});
            this.setState({pageLogo2: false});
            this.setState({pageLogoEnd: true});
        }
    }

    handleClickLogosIsologo(){
        if(this.state.pageLogoEnd){
            this.setState({pageLogoEnd: false});
        }else{
            var total = this.state.total;
            total = total + 5500;
            this.setState({total : total});
            this.setState({pageLogo2: false});
            this.setState({pageLogoEnd: true});
        }
    }

    //***************************************************************************************************************************************** */
    //***************************************************************************************************************************************** */
    //VIDEO PROMOCIONAL *********************************************************************************************************************** */
    //***************************************************************************************************************************************** */
    //***************************************************************************************************************************************** */

    handleClickVideoPromocionalLocacion(){
        if(this.state.pageVideoPromocional1){
            this.setState({pageVideoPromocional1: false});
        }else{
            // var total = this.state.total;
            // total = total + 1500;
            // this.setState({total : total});
            this.setState({pageLogo1: false});
            this.setState({pageVideoPromocional1: true});
        }
    }

    handleClickVideoPromocionalLocacionSi(){
        if(this.state.pageVideoPromocional2){
            this.setState({pageVideoPromocional2: false});
        }else{
            var total = this.state.total;
            total = total + 1499;
            this.setState({total : total});
            this.setState({pageVideoPromocional1: false});
            this.setState({pageVideoPromocional2: true});
        }
    }

    handleClickVideoPromocionalLocacionNo(){
        if(this.state.pageVideoPromocional2){
            this.setState({pageVideoPromocional2: false});
        }else{
            var total = this.state.total;
            this.setState({total : total});
            this.setState({pageVideoPromocional1: false});
            this.setState({pageVideoPromocional2: true});
        }
    }

    handleClickVideoPromocionalLocacionNoSe(){
        if(this.state.pageVideoPromocional2){
            this.setState({pageVideoPromocional2: false});
        }else{
            var total = this.state.total;
            total = total + 899;
            this.setState({total : total});
            this.setState({pageVideoPromocional1: false});
            this.setState({pageVideoPromocional2: true});
        }
    }

    handleClickVideoPromocionalLocacion1Minuto(){
        if(this.state.pageVideoPromocionalFinal){
            this.setState({pageVideoPromocionalFinal: false});
        }else{
            var total = this.state.total;
            total = total + 2999;
            this.setState({total : total});
            this.setState({pageVideoPromocional2: false});
            this.setState({pageVideoPromocionalFinal: true});
        }
    }

    handleClickVideoPromocionalLocacion2Minuto(){
        if(this.state.pageVideoPromocionalFinal){
            this.setState({pageVideoPromocionalFinal: false});
        }else{
            var total = this.state.total;
            total = total + 5999;
            this.setState({total : total});
            this.setState({pageVideoPromocional2: false});
            this.setState({pageVideoPromocionalFinal: true});
        }
    }

    handleClickVideoPromocionalLocacion3Minuto(){
        if(this.state.pageVideoPromocionalFinal){
            this.setState({pageVideoPromocionalFinal: false});
        }else{
            var total = this.state.total;
            total = total + 8999;
            this.setState({total : total});
            this.setState({pageVideoPromocional2: false});
            this.setState({pageVideoPromocionalFinal: true});
        }
    }

    //***************************************************************************************************************************************** */
    //***************************************************************************************************************************************** */
    //VIDEO EMPRESARIAL *********************************************************************************************************************** */
    //***************************************************************************************************************************************** */
    //***************************************************************************************************************************************** */

    handleClickVideoEmpresarialLocacion(){
        if(this.state.pageVideoEmpresarial1){
            this.setState({pageVideoEmpresarial1: false});
        }else{
            // var total = this.state.total;
            // total = total + 1500;
            // this.setState({total : total});
            this.setState({pageLogo1: false});
            this.setState({pageVideoEmpresarial1: true});
        }
    }

    handleClickVideoEmpresarialLocacionSi(){
        if(this.state.pageVideoEmpresarial2){
            this.setState({pageVideoEmpresarial2: false});
        }else{
            var total = this.state.total;
            total = total + 1499;
            this.setState({total : total});
            this.setState({pageVideoEmpresarial1: false});
            this.setState({pageVideoEmpresarial2: true});
        }
    }

    handleClickVideoEmpresarialLocacionNo(){
        if(this.state.pageVideoEmpresarial2){
            this.setState({pageVideoEmpresarial2: false});
        }else{
            var total = this.state.total;
            this.setState({total : total});
            this.setState({pageVideoEmpresarial1: false});
            this.setState({pageVideoEmpresarial2: true});
        }
    }

    handleClickVideoEmpresarialLocacionNoSe(){
        if(this.state.pageVideoEmpresarial2){
            this.setState({pageVideoEmpresarial2: false});
        }else{
            var total = this.state.total;
            total = total + 899;
            this.setState({total : total});
            this.setState({pageVideoEmpresarial1: false});
            this.setState({pageVideoEmpresarial2: true});
        }
    }

    handleClickVideoEmpresarialLocacion1Minuto(){
        if(this.state.pageVideoEmpresarialFinal){
            this.setState({pageVideoEmpresarialFinal: false});
        }else{
            var total = this.state.total;
            total = total + 2999;
            this.setState({total : total});
            this.setState({pageVideoEmpresarial2: false});
            this.setState({pageVideoEmpresarialFinal: true});
        }
    }

    handleClickVideoEmpresarialLocacion2Minuto(){
        if(this.state.pageVideoPromocionalFinal){
            this.setState({pageVideoPromocionalFinal: false});
        }else{
            var total = this.state.total;
            total = total + 5999;
            this.setState({total : total});
            this.setState({pageVideoEmpresarial2: false});
            this.setState({pageVideoPromocionalFinal: true});
        }
    }

    handleClickVideoEmpresarialLocacion3Minuto(){
        if(this.state.pageVideoPromocionalFinal){
            this.setState({pageVideoPromocionalFinal: false});
        }else{
            var total = this.state.total;
            total = total + 8999;
            this.setState({total : total});
            this.setState({pageVideoEmpresarial2: false});
            this.setState({pageVideoPromocionalFinal: true});
        }
    }

    //***************************************************************************************************************************************** */
    //***************************************************************************************************************************************** */
    //FOTO DE PRODUCTOS *********************************************************************************************************************** */
    //***************************************************************************************************************************************** */
    //***************************************************************************************************************************************** */

    handleClickFotoProductos(){
        if(this.state.pageFotoProductos){
            this.setState({pageFotoProductos: false});
        }else{
            // var total = this.state.total;
            // total = total + 1500;
            // this.setState({total : total});
            this.setState({pageLogo1: false});
            this.setState({pageFotoProductos: true});
        }
    }

    handleClickFotoProductosPequeños(){
        if(this.state.pageFotoProductos2){
            this.setState({pageFotoProductos2: false});
        }else{
            var total = this.state.total;
            total = total + 199;
            this.setState({total : total});
            this.setState({pageFotoProductos: false});
            this.setState({pageFotoProductos2: true});
        }
    }

    handleClickFotoProductosMedianos(){
        if(this.state.pageFotoProductos2){
            this.setState({pageFotoProductos2: false});
        }else{
            var total = this.state.total;
            total = total + 299;
            this.setState({total : total});
            this.setState({pageFotoProductos: false});
            this.setState({pageFotoProductos2: true});
        }
    }

    handleClickFotoProductosGrandes(){
        if(this.state.pageFotoProductos2){
            this.setState({pageFotoProductos2: false});
        }else{
            var total = this.state.total;
            total = total + 399;
            this.setState({total : total});
            this.setState({pageFotoProductos: false});
            this.setState({pageFotoProductos2: true});
        }
    }

    handleClickFotoProductoFondoSi(){
        if(this.state.pageFotoProductos3){
            this.setState({pageFotoProductos3: false});
        }else{
            var total = this.state.total;
            total = total;
            this.setState({total : total});
            this.setState({pageFotoProductos2: false});
            this.setState({pageFotoProductos3: true});
        }
    }

    handleClickFotoProductoFondoNo(){
        if(this.state.pageFotoProductos3){
            this.setState({pageFotoProductos3: false});
        }else{
            var total = this.state.total;
            total = total + 59;
            this.setState({total : total});
            this.setState({pageFotoProductos2: false});
            this.setState({pageFotoProductos3: true});
        }
    }

    handleClickFotoProductoFondoNoSe(){
        if(this.state.pageFotoProductos3){
            this.setState({pageFotoProductos3: false});
        }else{
            var total = this.state.total;
            total = total + 59;
            this.setState({total : total});
            this.setState({pageFotoProductos2: false});
            this.setState({pageFotoProductos3: true});
        }
    }

    handleClickFotoProductoModeloSi(){
        if(this.state.pageFotoProductosFinal){
            this.setState({pageFotoProductosFinal: false});
        }else{
            var total = this.state.total;
            total = total + 199;
            this.setState({total : total});
            this.setState({pageFotoProductos3: false});
            this.setState({pageFotoProductosFinal: true});
        }
    }

    handleClickFotoProductoModeloNo(){
        if(this.state.pageFotoProductosFinal){
            this.setState({pageFotoProductosFinal: false});
        }else{
            var total = this.state.total;
            total = total;
            this.setState({total : total});
            this.setState({pageFotoProductos3: false});
            this.setState({pageFotoProductosFinal: true});
        }
    }

    handleClickFotoProductoModeloNoSe(){
        if(this.state.pageFotoProductosFinal){
            this.setState({pageFotoProductosFinal: false});
        }else{
            var total = this.state.total;
            total = total + 199;
            this.setState({total : total});
            this.setState({pageFotoProductos3: false});
            this.setState({pageFotoProductosFinal: true});
        }
    }

    //***************************************************************************************************************************************** */
    //***************************************************************************************************************************************** */
    //CONTENIDO DE BLOGS *********************************************************************************************************************** */
    //***************************************************************************************************************************************** */
    //***************************************************************************************************************************************** */

    handleClickContenidoBlog(){
        if(this.state.pageContenidoBlog){
            this.setState({pageContenidoBlog: false});
        }else{
            // var total = this.state.total;
            // total = total + 1500;
            // this.setState({total : total});
            this.setState({pageLogo1: false});
            this.setState({pageContenidoBlog: true});
        }
    }

    handleClickContenidoBlog300(){
        if(this.state.pageContenidoBlog2){
            this.setState({pageContenidoBlog2: false});
        }else{
            var total = this.state.total;
            total = total + 359;
            this.setState({total : total});
            this.setState({pageContenidoBlog: false});
            this.setState({pageContenidoBlog2: true});
        }
    }

    handleClickContenidoBlog750(){
        if(this.state.pageContenidoBlog2){
            this.setState({pageContenidoBlog2: false});
        }else{
            var total = this.state.total;
            total = total + 559;
            this.setState({total : total});
            this.setState({pageContenidoBlog: false});
            this.setState({pageContenidoBlog2: true});
        }
    }

    handleClickContenidoBlog1500(){
        if(this.state.pageContenidoBlog2){
            this.setState({pageContenidoBlog2: false});
        }else{
            var total = this.state.total;
            total = total + 759;
            this.setState({total : total});
            this.setState({pageContenidoBlog: false});
            this.setState({pageContenidoBlog2: true});
        }
    }

    handleClickContenidoBlogImagenSi(){
        if(this.state.pageContenidoBlog3){
            this.setState({pageContenidoBlog3: false});
        }else{
            var total = this.state.total;
            total = total + 69;
            this.setState({total : total});
            this.setState({pageContenidoBlog2: false});
            this.setState({pageContenidoBlog3: true});
        }
    }

    handleClickContenidoBlogImagenNo(){
        if(this.state.pageContenidoBlog3){
            this.setState({pageContenidoBlog3: false});
        }else{
            var total = this.state.total;
            total = total;
            this.setState({total : total});
            this.setState({pageContenidoBlog2: false});
            this.setState({pageContenidoBlog3: true});
        }
    }

    handleClickContenidoBlogImagenNoSe(){
        if(this.state.pageContenidoBlog3){
            this.setState({pageContenidoBlog3: false});
        }else{
            var total = this.state.total;
            total = total + 69;
            this.setState({total : total});
            this.setState({pageContenidoBlog2: false});
            this.setState({pageContenidoBlog3: true});
        }
    }

    handleClickContenidoBlogVideoSi(){
        if(this.state.pageContenidoBlogFinal){
            this.setState({pageContenidoBlogFinal: false});
        }else{
            var total = this.state.total;
            total = total + 158;
            this.setState({total : total});
            this.setState({pageContenidoBlog3: false});
            this.setState({pageContenidoBlogFinal: true});
        }
    }

    handleClickContenidoBlogVideoNo(){
        if(this.state.pageContenidoBlogFinal){
            this.setState({pageContenidoBlogFinal: false});
        }else{
            var total = this.state.total;
            total = total;
            this.setState({total : total});
            this.setState({pageContenidoBlog3: false});
            this.setState({pageContenidoBlogFinal: true});
        }
    }

    handleClickContenidoBlogVideoNoSe(){
        if(this.state.pageContenidoBlogFinal){
            this.setState({pageContenidoBlogFinal: false});
        }else{
            var total = this.state.total;
            total = total + 158;
            this.setState({total : total});
            this.setState({pageContenidoBlog3: false});
            this.setState({pageContenidoBlogFinal: true});
        }
    }

    render(){
        const pageLogo1Act = this.state.pageLogo1;
        const pageLogo2Act = this.state.pageLogo2;
        const pageLogo3Act = this.state.pageLogoEnd;
        return(
            <div>
                <nav className="navbar navbar-default navbar-fixed white bootsnav on no-full">
                <div className="container">       
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                            <i className="fa fa-bars"></i>
                        </button>
                        <a className="navbar-brand" href="http://new.marketingdigital.xyz"><img src="img/logo.png" class="logo" alt=""/></a>
                    </div>
                    <div className="collapse navbar-collapse" id="navbar-menu">
                        <ul className="nav navbar-nav navbar-right" id="my-navigation" data-in="fadeInDown" data-out="fadeOutUp">
                            {/* <li  class="active scroll"><a href="#home">Inicio</a></li>
                            <li class="scroll"><a href="#features">Proceso</a></li>
                            <li class="scroll"><a href="#services">Servicios</a></li>
                            <li class="scroll"><a href="#pricing">Planes</a></li>
                            <li class="scroll"><a href="#screens">Portafolio</a></li> */}
                            <li class="scroll"><a href="#contact">${this.state.total} MXN</a></li>
                        </ul>
                    </div>
                </div>   
                </nav>

                {pageLogo1Act ?
                <div class="container-fluid">
                <div class="row">
                    <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Que tipo de trabajo desea?</h1>
                        <br/>
                        <br/>
                    </center>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                        <center>
                            <button type="button" class="btn btn-link" name="movil" id="movil" onClick={this.handleClickLogos}>
                                <img src="/img/coti/edit-tools.png" width="35%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='3'>Diseño de <br/>logotipo</font></p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                        <center>
                            <button type="button" class="btn btn-link" name="web" id="web" onClick={this.handleClickVideoPromocionalLocacion}>
                                <img src="/img/coti/video-camera.png" width="35%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='3'>Video <br/>promocional</font></p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                        <center>
                            <button type="button" class="btn btn-link" name="commerce" id="commerce" onClick={this.handleClickVideoEmpresarialLocacion}>
                                <img src="/img/coti/video.png" width="35%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='3'>Video <br/>empresarial</font></p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                        <center>
                            <button type="button" class="btn btn-link" name="commerce" id="commerce" onClick={this.handleClickFotoProductos}>
                                <img src="/img/coti/camera.png" width="35%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='3'>Fotos de <br/>productos</font></p>
                            </button>
                        </center>
                    </div>
                </div>
                <br/>
                <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-2 col-lg-3">
                        <center>
                            <button type="button" class="btn btn-link" name="commerce" id="commerce" onClick={this.handleClickContenidoBlog}>
                                <img src="/img/coti/blogging.png" width="35%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='3'>Contenido <br/>de Blogs</font></p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-2 col-lg-3">
                        <center>
                            <button type="button" class="btn btn-link" name="commerce" id="commerce">
                                <img src="/img/coti/social-media.png" width="35%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='3'>Contenido para <br/>redes sociales</font></p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-2 col-lg-3">
                        <center>
                            <button type="button" class="btn btn-link" name="commerce" id="commerce">
                                <img src="/img/coti/administracionredes.png" width="35%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='3'>Administración <br/>de redes</font></p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-2 col-lg-3">
                        <center>
                            <button type="button" class="btn btn-link" name="commerce" id="commerce">
                                <img src="/img/coti/ads.png" width="35%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='3'>Ads de Facebook <br/>y Google</font></p>
                            </button>
                        </center>
                    </div>
                    </div>
                </div>
                :
                <div></div>
                }

                {/* DISEÑO DE LOGOTIPO */}
                {pageLogo2Act ?
                <div class="container-fluid">
                <div class="row">
                    <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>Escoja que tipo de logotipo requiere</h1>
                        <br/>
                        <br/>
                    </center>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickLogosLogotipo}>
                                <img src="/img/coti/coca.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>Logotipo</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">La palabra con <br/>su tipografía.</p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickLogosIsotipo}>
                                <img src="/img/coti/apple.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>Isotipo</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">El simbolo o <br/>dibujo de <br/>la marca</p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickLogosImagotipo}>
                                <img src="/img/coti/spoti.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>Imagotipo</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Se combina <br/>el símbolo <br/>o dibujo con <br/>el texto</p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickLogosIsologo}>
                                <img src="/img/coti/startbucks.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>Isologo</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Fusión del <br/>símbolo o <br/>dibujo <br/>con el texto <br/>dentro. <br/>No pueden <br/>dividirse</p>
                            </button>
                        </center>
                    </div>
                </div>
                </div>
                :
                <div></div>
                }

                {pageLogo3Act ?
                <div class="container-fluid" style={{height: '100%'}}>
                    <center>
                    <div class="row">
                        <div class="col">
                        <center>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <h1 style={{color: 'aliceblue'}}>Total del trabajo</h1>
                            <br/>
                            <br/>
                        </center>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <center>
                                <h1 style={{color: 'aliceblue'}}>${this.state.total} Mxn</h1>
                            </center>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
                        <div class="col-xs-10 col-sm-10 col-md-8 col-lg-8">
                            <center>
                                <p style={{color: 'aliceblue'}}>El precio especificado es un aproximado de cuanto costaría crear un logotipo de este tipo, ya que se toma en cuenta las horas de trabajo, las reuniones con el cliente, las especificaciones finales del proyecto y el numero de propuestas.</p>
                            </center>
                        </div>
                        <div class="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
                    </div>
                    </center>
                </div>
                :
                <div></div>
                }

                {/* Video promocional */}
                {this.state.pageVideoPromocional1 ?
                <div class="container-fluid">
                <div class="row">
                    <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Se requiere grabar en diferentes locaciones?</h1>
                        <br/>
                        <br/>
                    </center>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickVideoPromocionalLocacionSi}>
                                <img src="/img/coti/route.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>Si</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">2 o mas <br/>locaciones.</p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickVideoPromocionalLocacionNo}>
                                <img src="/img/coti/placeholder.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>No</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Solo un <br/>lugar</p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickVideoPromocionalLocacionNoSe}>
                                <img src="/img/coti/nose.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>No se</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Podría requerir <br/>que sí.</p>
                            </button>
                        </center>
                    </div>
                </div>
                </div>
                :
                <div></div>
                }

                {this.state.pageVideoPromocional2 ?
                <div class="container-fluid">
                <div class="row">
                    <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Cuantos minutos quieres que tenga el video?</h1>
                        <br/>
                        <br/>
                    </center>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickVideoPromocionalLocacion1Minuto}>
                                <img src="/img/coti/number1.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>1 minuto</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Recomendable para <br/>promocionar una <br/>marca o un <br/>producto/servicio.</p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickVideoPromocionalLocacion2Minuto}>
                                <img src="/img/coti/number2.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>2 Minutos</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Recomendable para <br/>promocionar varios <br/> productos/servicios</p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickVideoPromocionalLocacion3Minuto}>
                                <img src="/img/coti/number3.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>3 Minutos</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Recomendable para <br/>promocionar una <br/>empresa.</p>
                            </button>
                        </center>
                    </div>
                </div>
                </div>
                :
                <div></div>
                }

                {this.state.pageVideoPromocionalFinal ?
                <div class="container-fluid" style={{height: '100%'}}>
                    <center>
                    <div class="row">
                        <div class="col">
                        <center>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <h1 style={{color: 'aliceblue'}}>Total del trabajo</h1>
                            <br/>
                            <br/>
                        </center>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <center>
                                <h1 style={{color: 'aliceblue'}}>${this.state.total} Mxn</h1>
                            </center>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
                        <div class="col-xs-10 col-sm-10 col-md-8 col-lg-8">
                            <center>
                                <p style={{color: 'aliceblue'}}>El precio especificado es un aproximado de cuanto costaría crear un video promocional, ya que se toma en cuenta las horas de trabajo, las reuniones con el cliente, las especificaciones finales del proyecto y el numero de propuestas.</p>
                            </center>
                        </div>
                        <div class="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
                    </div>
                    </center>
                </div>
                :
                <div></div>
                }

                {/* Video empresarial */}
                {this.state.pageVideoEmpresarial1 ?
                <div class="container-fluid">
                <div class="row">
                    <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Se requiere grabar en diferentes locaciones?</h1>
                        <br/>
                        <br/>
                    </center>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickVideoEmpresarialLocacionSi}>
                                <img src="/img/coti/route.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>Si</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">2 o mas <br/>locaciones.</p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickVideoEmpresarialLocacionNo}>
                                <img src="/img/coti/placeholder.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>No</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Solo un <br/>lugar</p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickVideoEmpresarialLocacionNoSe}>
                                <img src="/img/coti/nose.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>No se</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Podría requerir <br/>que sí.</p>
                            </button>
                        </center>
                    </div>
                </div>
                </div>
                :
                <div></div>
                }

                {this.state.pageVideoEmpresarial2 ?
                <div class="container-fluid">
                <div class="row">
                    <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Cuantos minutos quieres que tenga el video?</h1>
                        <br/>
                        <br/>
                    </center>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickVideoEmpresarialLocacion1Minuto}>
                                <img src="/img/coti/number1.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>1 minuto</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Recomendable para <br/>promocionar una <br/>marca o un <br/>producto/servicio.</p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickVideoEmpresarialLocacion2Minuto}>
                                <img src="/img/coti/number2.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>2 Minutos</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Recomendable para <br/>promocionar varios <br/> productos/servicios</p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickVideoEmpresarialLocacion3Minuto}>
                                <img src="/img/coti/number3.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>3 Minutos</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Recomendable para <br/>promocionar una <br/>empresa.</p>
                            </button>
                        </center>
                    </div>
                </div>
                </div>
                :
                <div></div>
                }

                {this.state.pageVideoEmpresarialFinal ?
                <div class="container-fluid" style={{height: '100%'}}>
                    <center>
                    <div class="row">
                        <div class="col">
                        <center>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <h1 style={{color: 'aliceblue'}}>Total del trabajo</h1>
                            <br/>
                            <br/>
                        </center>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <center>
                                <h1 style={{color: 'aliceblue'}}>${this.state.total} Mxn</h1>
                            </center>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
                        <div class="col-xs-10 col-sm-10 col-md-8 col-lg-8">
                            <center>
                                <p style={{color: 'aliceblue'}}>El precio especificado es un aproximado de cuanto costaría crear un video promocional, ya que se toma en cuenta las horas de trabajo, las reuniones con el cliente, las especificaciones finales del proyecto y el numero de propuestas.</p>
                            </center>
                        </div>
                        <div class="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
                    </div>
                    </center>
                </div>
                :
                <div></div>
                }

                {/* Fotos de productos */}
                {this.state.pageFotoProductos ?
                <div class="container-fluid">
                <div class="row">
                    <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Que tamaño tiene los productos a fotografiar?</h1>
                        <br/>
                        <br/>
                    </center>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickFotoProductosGrandes}>
                                <img src="/img/coti/duck.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>Grandes</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Perfecto para <br/>supermercados, juguetería, <br/>Feterrerías, etc.</p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickFotoProductosMedianos}>
                                <img src="/img/coti/reading-glasses.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>Medianos</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Perfecto para <br/>zapatos, bolsas, <br/>lentes, etc.</p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickFotoProductosPequeños}>
                                <img src="/img/coti/smartwatch.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>Pequeños</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Perfecto para, <br/> joyería y relojería.</p>
                            </button>
                        </center>
                    </div>
                </div>
                </div>
                :
                <div></div>
                }

                {this.state.pageFotoProductos2 ?
                <div class="container-fluid">
                <div class="row">
                    <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Las fotografias tendran un fondo?</h1>
                        <br/>
                        <br/>
                    </center>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickFotoProductoFondoSi}>
                                <img src="/img/coti/background.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>Si</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Fondo con paisaje.<br/> Perfecto para <br/>redes sociales</p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickFotoProductoFondoNo}>
                                <img src="/img/coti/background (1).png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>No</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Fondo blanco.<br/> Perfecto para <br/>tiendas en linea</p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickFotoProductoFondoNoSe}>
                                <img src="/img/coti/nose.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>No se</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Aun por definir</p>
                            </button>
                        </center>
                    </div>
                </div>
                </div>
                :
                <div></div>
                }

                {this.state.pageFotoProductos3 ?
                <div class="container-fluid">
                <div class="row">
                    <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Las fotografias tendran modelo?</h1>
                        <br/>
                        <br/>
                    </center>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickFotoProductoModeloSi}>
                                <img src="/img/coti/hat.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>Si</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Resalta el uso<br/> del producto</p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickFotoProductoModeloNo}>
                                <img src="/img/coti/smartwatch.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>No</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Solo quiero<br/> mostrar el producto</p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickFotoProductoModeloNoSe}>
                                <img src="/img/coti/nose.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>No se</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Aun por definir</p>
                            </button>
                        </center>
                    </div>
                </div>
                </div>
                :
                <div></div>
                }

                {this.state.pageFotoProductosFinal ?
                <div class="container-fluid" style={{height: '100%'}}>
                    <center>
                    <div class="row">
                        <div class="col">
                        <center>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <h1 style={{color: 'aliceblue'}}>Total del trabajo</h1>
                            <br/>
                            <br/>
                        </center>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <center>
                                <h1 style={{color: 'aliceblue'}}>${this.state.total} Mxn</h1>
                            </center>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
                        <div class="col-xs-10 col-sm-10 col-md-8 col-lg-8">
                            <center>
                                <p style={{color: 'aliceblue'}}>El precio especificado es un aproximado de cuanto costaría una foto por producto. Pregunta por nuestro paquetes en fotografías de productos.</p>
                            </center>
                        </div>
                        <div class="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
                    </div>
                    </center>
                </div>
                :
                <div></div>
                }

                {this.state.pageContenidoBlog ?
                <div class="container-fluid">
                <div class="row">
                    <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Cuantas palabras quieres que tenga el blog?</h1>
                        <br/>
                        <br/>
                    </center>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickContenidoBlog300}>
                                <img src="/img/coti/discussion.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>300 - 750</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Entretenidos, útiles<br/> y pensados en <br/> provocar una <br/>discusión</p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickContenidoBlog750}>
                                <img src="/img/coti/digital-marketing.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>750 - 1500</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Entretenido, y con <br/>contenido de calidad<br/> ó controversial <br/> tienden a <br/>compartirser más</p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickContenidoBlog1500}>
                                <img src="/img/coti/contract.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>1500 - 2250</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left">Los posts más extensos<br/>se posicionan <br/>más arriba con <br/>mayor facilidad</p>
                            </button>
                        </center>
                    </div>
                </div>
                </div>
                :
                <div></div>
                }

                {this.state.pageContenidoBlog2 ?
                <div class="container-fluid">
                <div class="row">
                    <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Desea que el blog contenga imágenes?</h1>
                        <br/>
                        <br/>
                    </center>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickContenidoBlogImagenSi}>
                                <img src="/img/coti/background.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>Si</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left"></p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickContenidoBlogImagenNo}>
                                <img src="/img/coti/blogging.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>No</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left"></p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickContenidoBlogImagenNoSe}>
                                <img src="/img/coti/nose.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>No se</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left"></p>
                            </button>
                        </center>
                    </div>
                </div>
                </div>
                :
                <div></div>
                }

                {this.state.pageContenidoBlog3 ?
                <div class="container-fluid">
                <div class="row">
                    <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Desea que el blog contenga un video?</h1>
                        <br/>
                        <br/>
                    </center>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickContenidoBlogVideoSi}>
                                <img src="/img/coti/video-player.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>Si</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left"></p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickContenidoBlogVideoNo}>
                                <img src="/img/coti/blogging.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>No</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left"></p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickContenidoBlogVideoNoSe}>
                                <img src="/img/coti/nose.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>No se</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left"></p>
                            </button>
                        </center>
                    </div>
                </div>
                </div>
                :
                <div></div>
                }

                {this.state.pageContenidoBlogFinal ?
                <div class="container-fluid" style={{height: '100%'}}>
                    <center>
                    <div class="row">
                        <div class="col">
                        <center>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <h1 style={{color: 'aliceblue'}}>Total del trabajo</h1>
                            <br/>
                            <br/>
                        </center>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <center>
                                <h1 style={{color: 'aliceblue'}}>${this.state.total} Mxn</h1>
                            </center>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
                        <div class="col-xs-10 col-sm-10 col-md-8 col-lg-8">
                            <center>
                                <p style={{color: 'aliceblue'}}>El precio especificado es un aproximado de cuanto costaría realizar el contenido para un blog. Pregunta por nuestro paquetes en contenido para blogs.</p>
                            </center>
                        </div>
                        <div class="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
                    </div>
                    </center>
                </div>
                :
                <div></div>
                }

                {/* CONTENIDO PARA REDES SOCIALES */}

                {this.state.pageContenidoRedes ?
                <div class="container-fluid">
                <div class="row">
                    <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Desea que el blog contenga un video?</h1>
                        <br/>
                        <br/>
                    </center>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickContenidoBlogVideoSi}>
                                <img src="/img/coti/video-player.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>Si</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left"></p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickContenidoBlogVideoNo}>
                                <img src="/img/coti/blogging.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>No</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left"></p>
                            </button>
                        </center>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickContenidoBlogVideoNoSe}>
                                <img src="/img/coti/nose.png" width="55%" alt=""/>
                                <br/>
                                <br/>
                                <p style={{color: 'aliceblue'}}><font size='5'>No se</font></p>
                                <p style={{color: 'aliceblue'}} class="text-md-left"></p>
                            </button>
                        </center>
                    </div>
                </div>
                </div>
                :
                <div></div>
                }
            </div>
        )
    }
}

export default Cotizador;