import React from 'react';
import logo from './logo.svg';
import './App.css';

//Componentes
import Cotizador from './components/Cotizador';

function App() {
  return (
    <div className="App">
      <Cotizador/>
    </div>
  );
}

export default App;
